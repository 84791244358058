<template>
    <console class="console"></console>
</template>

<script>
import console from "@/components/console"

export default {
  name: "App",
  components: {
    console
  }
}
</script>

<style lang="scss">
body,html{
  margin: 0;
  height: 100%;
}
*{
  scrollbar-width: thin;
  scrollbar-color: #444 #2220;
}
*::-webkit-scrollbar{
  width: 0.5rem;
  height: 0.5rem;
}
*::-webkit-scrollbar-track{
  background: none;
}
*::-webkit-scrollbar-thumb{
  background-color: #444;
  border-radius: 0.25rem;
}
#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: absolute;
  background-color: #222;
  min-height: 100%;
  width: 100%;
}
.console{
  position: absolute;
  width: 36rem;
  height: 100%;
  left: calc(50% - 18rem);
}
@media (max-width: 45rem){
  .console{
    width: 100%;
    left: 0;
  }
}
</style>