let commands = [
  {
    name: "unknown",
    output(input){return[
      {color: "white", content: `the command "${input[0]}" you entered is undefined!\n`, delay: 100},
      {
        color: "white",
        content:
        input.find(arr => arr === "--help")?
          "try something like:\n" +
          "\n" +
          "    - Mastodon   > social\n" +
          "    - Gitea      > git\n" +
          "    - Matrix     > element\n" +
          "    - Etherpad   > ether\n" +
          "    - short url  > surl\n" +
          "    - Jitsi      > meet\n":
          "show examples with \"welcome --help\"",
        delay: 200
      },
    ]}
  },
  {
    name: "welcome",
    output(input){
      let fontSize = window.getComputedStyle(document.body,null).fontSize.split("px", 1);
      return[
        {color: "white", content: "\nWelcome to:\n", delay: 100},
        {
          color: "green",
          content: window.innerWidth/fontSize<35?
            " ____                               \n" +
            "/\\  _`\\                             \n" +
            "\\ \\ \\/\\_\\  _ __   ___   __  __  __  \n" +
            " \\ \\ \\/_/_/\\`'__\\/ __`\\/\\ \\/\\ \\/\\ \\ \n" +
            "  \\ \\ \\L\\ \\ \\ \\//\\ \\L\\ \\ \\ \\_/ \\_/ \\\n" +
            "   \\ \\____/\\ \\_\\\\ \\____/\\ \\___x___/'\n" +
            "    \\/___/  \\/_/ \\/___/  \\/__//__/  \n" +
            "                         @hakkermanne\n" +
            "  ____               __       __     \n" +
            " /\\  _`\\            /\\ \\__  /'__`\\   \n" +
            " \\ \\ \\L\\ \\___   _ __\\ \\ ,_\\/\\ \\/\\ \\  \n" +
            "  \\ \\ ,__/ __`\\/\\`'__\\ \\ \\/\\ \\ \\ \\ \\ \n" +
            " __\\ \\ \\/\\ \\L\\ \\ \\ \\/ \\ \\ \\_\\ \\ \\_\\ \\\n" +
            "/\\_\\\\ \\_\\ \\____/\\ \\_\\  \\ \\__\\\\ \\____/\n" +
            "\\/_/ \\/_/\\/___/  \\/_/   \\/__/ \\/___/ \n\n"
            :
            " ____                                 ____               __       __     \n" +
            "/\\  _`\\                              /\\  _`\\            /\\ \\__  /'__`\\   \n" +
            "\\ \\ \\/\\_\\  _ __   ___   __  __  __   \\ \\ \\L\\ \\___   _ __\\ \\ ,_\\/\\ \\/\\ \\  \n" +
            " \\ \\ \\/_/_/\\`'__\\/ __`\\/\\ \\/\\ \\/\\ \\   \\ \\ ,__/ __`\\/\\`'__\\ \\ \\/\\ \\ \\ \\ \\ \n" +
            "  \\ \\ \\L\\ \\ \\ \\//\\ \\L\\ \\ \\ \\_/ \\_/ \\ __\\ \\ \\/\\ \\L\\ \\ \\ \\/ \\ \\ \\_\\ \\ \\_\\ \\\n" +
            "   \\ \\____/\\ \\_\\\\ \\____/\\ \\___x___/'/\\_\\\\ \\_\\ \\____/\\ \\_\\  \\ \\__\\\\ \\____/\n" +
            "    \\/___/  \\/_/ \\/___/  \\/__//__/  \\/_/ \\/_/\\/___/  \\/_/   \\/__/ \\/___/ \n" +
            "                                                                         \n" +
            "@hakkermanne\n\n",
          delay: 300
        },
        input.find(arr => arr === "--help")?{
          color: "white",
          content:
            "web services:\n" +
            "\n" +
            "    - Pleroma    > social\n" +
            "    - Gitea      > git\n" +
            "    - Airsonic   > air\n" +
            "    - Backup     > air2\n" +
            "    - Jellyfin   > fin\n" +
            "    - Homepage   > home\n" +
            "    - Theme      > adb\n",
          delay: 500
        }:{color: "white", content: "show examples with \"welcome --help\"", delay: 600}
      ]
    }
  },
  {
    name: "time",
    output(){ return[
      {color: "white", content: "your current time is: ", delay: 100},
      {color: "blue", content: function(){return new Date().toLocaleTimeString()}(), delay: 200},
    ]}
  },
  {
    name: "date",
    output(){ return[
      {color: "white", content: "your current date is: ", delay: 100},
      {color: "blue", content: function(){return new Date().toLocaleDateString()}(), delay: 200},
    ]}
  },
  {
    name: ["social", "pleroma", "Pleroma"],
    output:[
      {color: "blue", content: "\nCrowsocial.us.to", delay: 100},
      {color: "white", content: " is loading\n", delay: 200},
      {color: "white", content: "forwarding", delay: 400},
      {color: "white", content: " ...", forward: "https://crowsocial.us.to/", delay: 600}
    ]
  },
  {
    name: ["git", "gitea", "Gitea"],
    output:[
      {color: "blue", content: "\nGitea", delay: 100},
      {color: "white", content: " is loading\n", delay: 200},
      {color: "white", content: "forwarding", delay: 400},
      {color: "white", content: " ...", forward: "https://crow.port0.org/git/", delay: 600}
    ]
  },
  {
    name: ["air", "Air", "airsonic", "Airsonic"],
    output:[
      {color: "blue", content: "\nAirsonic", delay: 100},
      {color: "white", content: " is loading\n", delay: 200},
      {color: "white", content: "forwarding", delay: 400},
      {color: "white", content: " ...", forward: "https://crowsonic.epicgamer.org/", delay: 600}
    ]
  },
  {
    name: ["air2", "Air2", "Backup"],
    output:[
      {color: "blue", content: "\nBackup Airsonic Server", delay: 100},
      {color: "white", content: " is loading\n", delay: 200},
      {color: "white", content: "forwarding", delay: 400},
      {color: "white", content: " ...", forward: "https://crow.port0.org/airsonic/", delay: 600}
    ]
  },
  {
    name: ["fin", "Fin", "Jellyfin"],
    output:[
      {color: "blue", content: "\nJellyfin Server", delay: 100},
      {color: "white", content: " is loading\n", delay: 200},
      {color: "white", content: "forwarding", delay: 400},
      {color: "white", content: " ...", forward: "https://crow.port0.org/jellyfin/", delay: 600}
    ]
  },
  {
    name: ["home", "Home", "Homepage"],
    output:[
      {color: "blue", content: "\nHomepage", delay: 100},
      {color: "white", content: " is loading\n", delay: 200},
      {color: "white", content: "forwarding", delay: 400},
      {color: "white", content: " ...", forward: "https://crow.epicgamer.org", delay: 600}
    ]
  },
  {
    name: ["theme", "Theme", "Adb", "ADB", "adb", "@ADB", "@adb"],
    output:[
      {color: "blue", content: "\nabd.sh", delay: 100},
      {color: "white", content: " is loading\n", delay: 200},
      {color: "white", content: "forwarding", delay: 400},
      {color: "white", content: " ...", forward: "https://adb.sh", delay: 600}
    ]
  },
  {
    name: "cat",
    output:[
      {
        color: "red",
        content: "" +
          "    .       .\n" +
          "    \\`-\"'\"-'/\n" +
          "     } 6 6 {    \n" +
          "    =.  Y  ,=   \n" +
          "      /^^^\\  .\n" +
          "     /     \\  )\n" +
          "jgs (  )-(  )/ \n" +
          "     \"\"   \"\"",
        delay: 100}
    ]
  }
]

export default {
  data(){
    return {
      commands
    }
  },
  methods:{
    renderCommand(input){
      let toRender = this.getCommand(input).output
      return typeof toRender === "function"?toRender(input.split(" ")):toRender;
    },
    getCommand(input) {
      input = input.split(" ", 1)[0];
      let command = commands.find(
        command => Array.isArray(command.name)?command.name.find(
          altName => altName === input):command.name === input);
      return command?command:commands[0];
    }
  }
}
